<template>
  <div
    v-if="issue && issue.status == '已完成'"
    class="pb-8 committes-replies"
  >
    <van-cell-group
      title="业委会答复"
    >
      <van-cell
        v-if="issue.finishedAt"
        title="答复时间"
        :value="$dayjs(issue.finishedAt).format('YYYY-MM-DD HH:mm:ss')"
      />
      <van-cell
        title="答复内容"
        :label="issue.reply ? issue.reply : '-'"
      />
    </van-cell-group>

    <van-cell-group
      v-if="reply_attachments && reply_attachments.length > 0"
      title="答复照片"
    >
      <van-cell
        class="issue-item pt-4 no-uploader"
      >
        <template #title>
          <van-uploader
            v-model="reply_attachments"
            :readonly="true"
            :preview-options="replyAttachmentsPreviewOptions"
            multiple
          />
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'IssueReply',
  props: {
    issue: {
      type: Object,
      default: null
    }
  },
  computed: {
    reply_attachments() {
      if(!Array.isArray(this.issue.reply_attachments)) {
        return []
      }
      return this.issue.reply_attachments.map((url) => ({url,
            isImage: true,
            deletable: false
      }))
    },
    replyAttachmentsPreviewOptions() {
      if(!Array.isArray(this.issue.reply_attachments)) {
        return {}
      }
      return { images: this.issue.reply_attachments}
    }
  }
}
</script>
<style lang="scss" scoped>
.issue-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .van-cell__value {
    display: none;
  }
}
</style>