<template>
  <section
    class="min-h-screen pt-2"
    :class="loading ? '' : 'bg-gray-100'"
  >
    <van-skeleton
      v-if="loading"
      class="basic-skeleton"
      :row="5"
    />
    <template v-else>
      <Basic :issue="issue" />
      <Reply :issue="issue" />
      <div
        v-if="issue && issue.status == '待处理'"
        class="w-full px-4 pb-6 mt-6"
      >
        <van-button
          type="danger"
          block
          round
          :loading="processing"
          loading-text="删除中..."
          @click="remove"
        >
          删除当前意见/建议
        </van-button>
      </div>
      <van-empty
        v-if="!issue"
        description="无法加载意见/建议信息，请稍后再试！"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Basic from '@/components/issue/basic'
import Reply from './components/reply'
import share from '@/mixins/share'
export default {
  name: 'PublicIssueViewer',
  components: {
    Basic,
    Reply
  },
  mixins:[share],
  data() {
    return {
      loading: true,
      processing: false,
      issue: null
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb'])
  },
  mounted() {
    this.fetch()
    this.disableShareMenu()
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const response = await this.tcb.callFunction({
          name: 'issues',
          data: {
            $url: 'subscriberGet',
            id: this.$route.params.issue
          }
        })
        // eslint-disable-next-line no-console
        console.warn('获取到相关意见/建议', response)
        if (response.result?.error) {
          this.$notify({ type: 'danger', message: response.result.error })
        } else {
          let issue = response.result.data
          this.$set(this, 'issue', issue)
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取意见和建议', err)
        this.issue = null
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },
    remove() {
      this.processing = true
      const data = {
        $url: 'subscriberRemove',
        id: this.$route.params.issue
      }
      // eslint-disable-next-line no-console
      console.warn('remove', JSON.stringify(data))
      this.tcb
        .callFunction({
          name: 'issues',
          data
        })
        .then(res => {
          // eslint-disable-next-line no-console
          console.log('删除意见建议', res)
          if (res.result.error) {
            this.$notify({
              type: 'danger',
              message: res.result.error
            })
          } else {
            this.$dialog
              .confirm({
                title: '删除成功',
                message: '您的意见/建议已删除！',
                theme: 'round-button',
                showCancelButton: false,
                confirmButtonText: '返回列表'
              })
              .then(() => {
                this.$router.replace({
                  name: 'public.issues'
                })
              })
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$notify({
            type: 'danger',
            message: '删除失败，由于微信服务异常，暂时无法删除'
          })
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-skeleton {
  padding: 30px 16px !important;
}
</style>
