<template>
  <div
    v-if="issue"
  >
    <van-cell-group
      v-if="issue.subscriber && issue.subscriber.nickName"
      title="微信信息"
      class="mb-2"
    >
      <van-cell
        title="昵称"
        :value="issue.subscriber.nickName"
      />
      <van-cell
        v-if="issue.subscriber.subscribe_time"
        title="关注时间"
        :value="$dayjs(issue.subscriber.subscribe_time).format('YYYY-MM-DD HH:mm:ss')"
      />
      <van-cell
        v-if="issue.subscriber.avatarUrl"
        center
        class="py-0"
        title="头像"
      >
        <template #default>
          <van-image
            style="margin-top: 2px"
            round
            width="32"
            height="32"
            :src="issue.subscriber.avatarUrl"
          />
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group
      title="基本信息"
      class="mb-2"
    >
      <van-cell class="issue-item">
        <template #title>
          <p class="font-normal">
            {{ issue.title }}
          </p>
          <p class="mt-2">
            <span class="text-gray-300">创建时间：</span><span class="text-gray-900">{{
              $dayjs(issue.createdAt).format('YYYY-MM-DD HH:mm:ss')
            }}</span>
          </p>
          <p class="mt-2">
            <span class="text-gray-300">类别：</span><span class="text-gray-900">{{ issue.category }}</span>
          </p>

          <p class="mt-2">
            <span class="text-gray-300">姓名：</span><span class="text-gray-900">{{ issue.name || '无' }}</span>
          </p>

          <p
            v-if="issue.mobile"
            class="mt-2"
          >
            <span class="text-gray-300">电话：</span><span

              class="text-gray-900"
            >{{ issue.mobile || '无' }}</span><a
              v-if="module === 'workspace'"
              :href="`tel:${issue.mobile}`"
              class="text-red-800 no-underline"
            >（<van-icon name="phone" /> <span
              class="underline "
            >拨打电话</span>）</a>
          </p>

          <p class="mt-2">
            <span class="text-gray-300">楼号：</span><span class="text-gray-900">{{ issue.unit || '无' }}</span>
          </p>

          <p class="mt-2">
            <span class="text-gray-300">房号：</span><span class="text-gray-900">{{ issue.room || '无' }}</span>
          </p>
          <p
            v-if="issue.complaintAt"
            class="mt-2"
          >
            <span class="text-gray-300">首次向物业投诉时间：</span><span class="text-gray-900">{{
              $dayjs(issue.complaintAt).format('YYYY-MM-DD')
            }}</span>
          </p>
          <p class="mt-2">
            <span class="text-gray-300">状态：</span><van-tag
              plain
              :type="
                issuesStatus[issue.status]
                  ? issuesStatus[issue.status]
                  : 'primary'
              "
              class="px-2 py-1"
            >
              {{ issuesStatusName[issue.status] ? issuesStatusName[issue.status] : issue.status }}
            </van-tag>
          </p>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group
      title="内容"
      class="mb-2"
    >
      <van-cell class="issue-item">
        <template #title>
          <p>
            {{ issue.content || '无' }}
          </p>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group
      v-if="attachments && attachments.length > 0"
      title="照片"
      class="mb-2"
    >
      <van-cell
        class="pt-4 issue-item no-uploader"
      >
        <template #title>
          <van-uploader
            v-model="attachments"
            :readonly="true"
            :preview-options="attachmentsPreviewOptions"
            multiple
          />
        </template>
      </van-cell>
    </van-cell-group>


    <van-cell-group
      v-if="issue.remark"
      title="备注"
      class="mb-2"
    >
      <van-cell class="issue-item">
        <template #title>
          <p>
            {{ issue.remark || '无' }}
          </p>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ComponentIssueBasic',
  props: {
    module: {
      type: String,
      default: 'public'
    },
    issue: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState('Common/Config', ['issuesStatus', 'issuesStatusName']),
    attachments() {
      if(!Array.isArray(this.issue.attachments)) {
        return []
      }
      return this.issue.attachments.map((url) => ({url,
            isImage: true,
            deletable: false
      }))
    },
    attachmentsPreviewOptions() {
      if(!Array.isArray(this.issue.attachments)) {
        return {}
      }
      return { images: this.issue.attachments}
    }
  }
}
</script>
<style lang="scss" scoped>
.issue-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .van-cell__value {
    display: none;
  }
}
</style>